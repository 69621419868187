<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Edit Timezone</h2>
    </template>

    <template #page-content>
      <div id="edit-timezone">
        <div v-if="currentUser != null">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(save)">
              <div
                class="buttons"
                style="border-bottom: 0.1px solid rgba(100, 100, 100, 0.1)"
              >
                <b-button
                  type="is-primary"
                  outlined
                  icon-left="close"
                  :disabled="isSaving"
                  @click="$router.push('/settings/personal-info')"
                >
                  Cancel
                </b-button>
                <b-button
                  type="is-primary"
                  icon-left="check"
                  native-type="submit"
                  :loading="isSaving"
                >
                  Save
                </b-button>
              </div>

              <p class="title">Time Zone Information</p>

              <b-field label="Time Zone">
                <b-select
                  rules="required"
                  placeholder="Select a Time zone"
                  v-model="selectedTimezone"
                >
                  <optgroup label="Indonesian Timezones">
                    <option
                      v-for="option in indoTimezones"
                      :value="option"
                      :key="option"
                    >
                      {{ option + '' + getTimezonesOffset(option) }}
                    </option>
                  </optgroup>
                  <optgroup label="Other Timezones">
                    <option
                      v-for="option in otherTimezones"
                      :value="option"
                      :key="option"
                    >
                      {{ option + '' + getTimezonesOffset(option) }}
                    </option>
                  </optgroup>
                </b-select>
              </b-field>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import ApiService from '../../../services/common/api.service'
import { showToast } from '../../../services/util'

export default {
  watch: {
    currentUser: function () {
      if (this.currentUser != null) {
        this.selectedTimezone = this.currentUser.timezone
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    }),
  },
  data() {
    return {
      isSaving: false,
      listTimezone: moment.tz.names(),
      indoTimezones: null,
      otherTimezones: null,
      selectedTimezone: null,
    }
  },
  methods: {
    ...mapMutations({
      setTimezone: 'user/setTimezone',
    }),
    async save() {
      try {
        await ApiService.post('/api/space-roketin/user/update-timezone', {
          timezone: this.selectedTimezone,
        })

        this.setTimezone(this.selectedTimezone)
        showToast('Timezone updated', 'is-success', 'is-top')
      } catch (err) {
        console.log(err)
      }
    },
    getIndonesiaTimezones() {
      let indexJakarta = this.listTimezone.indexOf('Asia/Jakarta')
      let indexMakassar = this.listTimezone.indexOf('Asia/Makassar')
      let indexJayapura = this.listTimezone.indexOf('Asia/Jayapura')

      let indoTimezones = []
      indoTimezones.push(this.listTimezone[indexJakarta])
      indoTimezones.push(this.listTimezone[indexMakassar])
      indoTimezones.push(this.listTimezone[indexJayapura])

      return indoTimezones
    },
    spliceListTimezones() {
      let otherTimezone = this.listTimezone
      let indexJakarta = otherTimezone.indexOf('Asia/Jakarta')
      otherTimezone.splice(indexJakarta, 1)

      let indexMakassar = otherTimezone.indexOf('Asia/Makassar')
      otherTimezone.splice(indexMakassar, 1)

      let indexJayapura = otherTimezone.indexOf('Asia/Jayapura')
      otherTimezone.splice(indexJayapura, 1)

      return otherTimezone
    },
    sortingTimezone() {
      this.indoTimezones = this.getIndonesiaTimezones()
      this.otherTimezones = this.spliceListTimezones()
    },
  },
  mounted() {
    if (this.currentUser != null) {
      this.selectedTimezone = this.currentUser.timezone
    }
    this.sortingTimezone()
  },
}
</script>
